// Import des composants et des styles nécessaires
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Tab, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import moment from "moment";
import "moment/locale/fr";
import axios from "axios";
import Alert from "@mui/material/Alert";
import styles from "./Calendrier.module.css";
import Slider from "react-slick";
import Tooltip from "@mui/material/Tooltip";
import LocalStorage from "../../../../../Utils/localStorageService";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{}}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ userData }) {
  const [value, setValue] = useState(0);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isReservationPopupOpen, setReservationPopupOpen] = useState(false);
  const [selectedWorkshop, setSelectedWorkshop] = useState(
    userData.workshops["0"]
  );
  const [selectedSessionId, setSelectedSessionId] = useState(null); // Déclaration de selectedSessionId

  const [showLoginMessage, setShowLoginMessage] = useState(false);
  const [etatAbn, setEtatAbn] = useState(null);
  const [nbrWorkshop, setnbrWorkshop] = useState(null);
  const [idClient, setIdClient] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const sliderRef = React.useRef();

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const handleOpenReservationPopup = (sessionId) => {
    setSelectedSessionId(sessionId); // Mettre à jour selectedSessionId avec l'ID de la session sélectionnée
    setReservationPopupOpen(true);
  };

  const handleCloseReservationPopup = () => {
    setSelectedSessionId(null); // Réinitialiser selectedSessionId lorsque la boîte de dialogue est fermée
    setReservationPopupOpen(false);
  };

  useEffect(() => {
    const fetchClientData = async () => {
      const token = localStorage.getItem("access_token_story"); // Adjust the key if necessary
      if (token) {
        try {
          const clientData = await axios.get(
            `${process.env.REACT_APP_APIURL}/client/g/get/p/profile`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (clientData.data.status === "success") {
            const result = clientData.data.result;
            setEtatAbn(result.etat_abn);
            setIdClient(result.id_client);
            setnbrWorkshop(result.nbr_workshops);
            
          } else {
            console.error("Failed to fetch client data.");
          }
        } catch (error) {
          console.error("Error fetching client data:", error);
        }
      }
    };

    fetchClientData(); // Call the async function
  }, []);

  const handlePaiementReservation = (
    workshopId,
    sessionId,
    capacity,
    price,
    sessionDate
  ) => {
    const itemToBuyId = workshopId; // Utiliser l'identifiant de l'atelier comme itemToBuyId
    const itemToBuyType = "WORKSHOPS";
    const sessionid = sessionId;
    const token = LocalStorage.getAccessToken();

    console.log(
      "partisipant accessible :",
      selectedWorkshop.is_accessible_participant
    );
    // Vérifier si le token est disponible
    const selectedSession = selectedWorkshop.sessions.find(
      (session) => session.id_wss === selectedSessionId
    );

    if (selectedSession) {
      if (selectedSession.capacity > selectedSession.participant_count_wss) {
        if (
          etatAbn === 1 &&
          nbrWorkshop !== 0 &&
          selectedWorkshop.is_accessible_participant === 1
        ) {
          const data = {
            id_sessions: sessionid,
            id_ws: itemToBuyId,
            id_client: idClient,
          };
          axios
            .post(
              `${process.env.REACT_APP_APIURL}/process-order-workshops-abonner/public/c`,
              data,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((response) => {
              setLoading(false);
              toast.success("Votre réservation a été effectuée avec succès");
              const message = encodeURIComponent(
                "Votre réservation a été effectuée avec succès ! Vous allez recevoir un message de confirmation de votre réservation."
              );
              window.location.href = `/message?message=${message}`;
            })
            .catch((error) => {
              setLoading(false);
              if (error.response) {
                toast.error(error.response.data.error);
              } else if (error.request) {
                toast.error("No response from server. Please try again.");
              } else {
                toast.error("An unexpected error occurred.");
              }
            });
        } else if (etatAbn === 1 && nbrWorkshop === 0) {
          const data = {
            itemToBuyId,
            itemToBuyType,
            sessionid,
            token: token,
          };

          axios
            .post(
              `${process.env.REACT_APP_BILLING_URL}/create-checkout-session`,
              data,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((response) => {
              setLoading(false);
              window.location.href = response.data.url;
            })
            .catch((error) => {
              setLoading(false);
              if (error.response) {
                toast.error(error.response.data.error);
              } else if (error.request) {
                toast.error("No response from server. Please try again.");
              } else {
                toast.error("An unexpected error occurred.");
              }
            });
        } else if (etatAbn === 0 || etatAbn === null) {
          const data = {
            itemToBuyId,
            itemToBuyType,
            sessionid,
            token: token,
          };

          axios
            .post(
              `${process.env.REACT_APP_BILLING_URL}/create-checkout-session`,
              data,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((response) => {
              setLoading(false);
              window.location.href = response.data.url;
            })
            .catch((error) => {
              setLoading(false);
              if (error.response) {
                toast.error(error.response.data.error);
              } else if (error.request) {
                toast.error("No response from server. Please try again.");
              } else {
                toast.error("An unexpected error occurred.");
              }
            });
        } else {
          setLoading(false);
          toast.error("Invalid reservation status.");
        }
      } else {
        setLoading(false);
        toast.error(
          "Oups ! Toutes les places pour cet atelier sont déjà réservées."
        );
      }
    } else {
      setLoading(false);
      toast.error("Session not found.");
    }
  };

  if (!userData || !userData.workshops || userData.workshops.length === 0) {
    // Handle the case where userData or workshops data is null or undefined
    return (
      <div className={styles["no-workshops-message-container"]}>
        <Alert className={styles["no-workshops-message"]} severity="info">
          Pas d'ateliers disponible pour le moment
        </Alert>
      </div>
    );
  }

  const workshops = userData.workshops;

  const handleTabClick = (index) => {
    setValue(index);
    sliderRef.current.slickGoTo(index);
  };
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setSelectedWorkshop(workshops[newValue]);
  };

  const numElements = workshops.length;

  const sliderSettings = {
    arrows: true,
    style: { maxWidth: "1100px", margin: "0 auto" },
    prevArrow: <div className={styles["slick-prev"]}></div>,
    nextArrow: <div className={styles["slick-next"]}></div>,
    infinite: true,
    dots: false,
    speed: 500,
    slidesToShow: Math.min(4, numElements),
    slidesToScroll: 1,
    autoplay: true, // Désactiver le défilement automatique
    autoplaySpeed: 100000000000000, // Ajoutez une valeur élevée

    pauseOnHover: false,
    initialSlide: 0,
    centerMode: numElements < 4,
    centerPadding: numElements < 4 ? "30%" : undefined,
    beforeChange: handleTabChange,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "10%",
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          style: {
            maxWidth: "100%",
            margin: "0 auto",
            textAlign: "center",
          },
        },
      },
    ],
  };

  const handleClose = () => setShowLoginMessage(false);

  return (
    <Box sx={{ width: "100%" }}>
      <Slider
        ref={sliderRef}
        {...sliderSettings}
        style={{ padding: "0 !important" }}>
        {workshops.map((workshop, index) => (
          <div key={index}>
            <Tab
              label={workshop.title_ws}
              {...a11yProps(index)}
              sx={{
                color: index === value ? "#fff" : "#F3778F",
                backgroundColor: index === value ? "#f3778f" : "#ffe9ed",
                borderRadius: "10px",
                margin: "auto",
                cursor: "pointer",
                maxWidth: "90%",
                height: "8vh",
                fontSize: "1rem",
                whiteSpace: "normal",
                fontWeight: "bold",
                textAlign: "center",
              }}
              onClick={() => handleTabClick(index)}
            />
          </div>
        ))}
      </Slider>
      <Modal show={showLoginMessage} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Message de connexion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Veuillez vous connecter pour passer une commande.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <Link to="/login">
            <Button variant="primary">Connexion</Button>
          </Link>
        </Modal.Footer>
      </Modal>
      {selectedWorkshop && (
        <CustomTabPanel value={value} index={value}>
          <Paper
            elevation={8}
            sx={{
              p: 3,
              backgroundColor: "#fff",
              borderRadius: "20px",
              width: "40% !important",
              boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.3)",
              "@media (max-width: 1250px)": {
                width: "100% !important",
              },
            }}
            className={styles["container-calendrier"]}
            onMouseEnter={() => sliderRef.current.slickPause()}
            onMouseLeave={() => sliderRef.current.slickPlay()}>
            <div className={styles["calendar light"]}>
              <div className={styles["calendar_header"]}>
                <p className={styles["header_copy"]}>
                  {" "}
                  Calendrier des ateliers
                </p>
              </div>
              <div className={styles["calendar_plan"]}>
                <div className={styles["cl_plan"]}>
                  <div className={styles["cl_title"]}>
                    <b>{selectedWorkshop.title_ws}</b>
                  </div>
                  <div className={styles["cl_copy"]}>
                    {moment(selectedWorkshop.session_date_ws)
                      .locale("fr")
                      .format("D MMMM YYYY [-] HH[h]mm")}
                  </div>
                  <Tooltip title="Plus d'infos" placement="top" arrow>
                    <div className={styles["cl_add"]} onClick={handleOpenPopup}>
                      <i className="fas fa-plus"></i>
                    </div>
                  </Tooltip>
                </div>
              </div>
              <Dialog open={isPopupOpen} onClose={handleClosePopup}>
                <DialogTitle>Détails de l'atelier</DialogTitle>
                <DialogContent>
                  <p>
                    <b>Titre:</b> {selectedWorkshop.title_ws}
                  </p>
                  <p>
                    <b>Description:</b> {selectedWorkshop.description_ws}
                  </p>
                  <p>
                    <b>Capacité:</b> {selectedWorkshop.capacity_ws} personnes
                  </p>
                  <p>
                    <b>Prix:</b> {selectedWorkshop.price_ws} €
                  </p>
                  <p>
                    <b>Sessions disponibles:</b> {selectedWorkshop.nbr_ws}
                  </p>
                  <p>
                    <b>Durée:</b> {selectedWorkshop.duration_ws} minutes
                  </p>
                  <p>
                    <b>Date:</b>{" "}
                    {moment(selectedWorkshop.session_date_ws)
                      .locale("fr")
                      .format("D MMMM YYYY [-] HH[h]mm")}
                  </p>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleClosePopup}
                    variant="outlined"
                    style={{
                      borderColor: "rgb(255, 168, 182)",
                      color: "rgb(255, 168, 182)",
                    }}>
                    Fermer
                  </Button>
                </DialogActions>
              </Dialog>

              <div className={styles["calendar_events"]}>
                <p className={styles["ce_title"]}>Toutes les sessions à venir</p>

                {selectedWorkshop.sessions.map((session, sessionIndex) => (
                  <div className={styles["event_item"]} key={sessionIndex}>
                    <div className={styles["date-event"]}>
                      <i
                        className={`fa-solid fa-circle ${
                          sessionIndex === 0
                            ? styles.circleFirst
                            : styles.circle
                        }`}
                      />
                      <div className={styles["ei_Title"]}>
                        {moment(session.date_wss)
                          .locale("fr")
                          .format("D MMMM YYYY [-] HH[h]mm")}
                      </div>
                    </div>
                    <Button
                      onClick={() => handleOpenReservationPopup(session.id_wss)}
                      className={styles["btn-reserver"]}
                      size="small">
                      Réserver
                    </Button>{" "}
                    <Dialog
                      open={
                        isReservationPopupOpen &&
                        selectedSessionId === session.id_wss
                      }
                      onClose={handleCloseReservationPopup}>
                      <DialogTitle>  <p className={styles["ce_title_2"]}>Réservez votre place</p></DialogTitle>
                      <DialogContent>
                        <p>
                          <b>Session : </b>
                          {session.id_wss}
                        </p>
                        
                        <p>
                          <b>Date de session :</b>{" "}
                          {moment(session.date_wss)
                            .locale("fr")
                            .format("D MMMM YYYY [-] HH[h]mm")}
                        </p>
                        <p>
                          <b>Details:</b> {selectedWorkshop.description_ws}
                        </p>
                        <p>
                          <b>Capacité :</b> {session.capacity} personnes
                        </p>
                        <p>
                          <b>Prix:</b> {selectedWorkshop.price_ws} €
                        </p>
                        <p>
                          <b>Durée:</b> {selectedWorkshop.duration_ws} minute
                        </p>
                        <p>
                          <b>Nombre de participants réservés :</b>{" "}
                          {session.participant_count_wss}
                        </p>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          autoFocus
                          onClick={() =>
                            handlePaiementReservation(
                              selectedWorkshop.id_ws,
                              session.id_wss,
                              session.capacity,
                              selectedWorkshop.price_ws,
                              session.date_wss
                            )
                          } // Passer l'identifiant de l'atelier ici
                          variant="contained"
                          style={{ backgroundColor: "rgb(255, 168, 182)" }}>
                          Réserver
                        </Button>
                        <Button
                          onClick={handleCloseReservationPopup}
                          autoFocus
                          variant="outlined"
                          style={{
                            borderColor: "rgb(255, 168, 182)",
                            color: "rgb(255, 168, 182)",
                          }}>
                          Annuler
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                ))}

                {etatAbn === 1 && (
                  <p>
                    {selectedWorkshop.is_accessible_participant === 1 ? (
                      <span style={{ fontStyle: "italic", color: "green" }}>
                        * Session autorisée pour les abonnés ayant des jetons.
                        Sinon, veuillez procéder au paiement pour participer
                      </span>
                    ) : (
                      <span style={{ fontStyle: "italic", color: "red" }}>
                        * Les jetons abonnés ne sont pas acceptés. Le paiement
                        direct est requis pour réserver la session.
                      </span>
                    )}
                  </p>
                )}
              </div>
            </div>
          </Paper>
        </CustomTabPanel>
      )}
    </Box>
  );
}

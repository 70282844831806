import React from "react";
import { Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import rounded_back from "../../../../../../../Assets/images/Ellipse 5.png";
import GlobalContext from "../../../../../../../Context/GlobalContext";
import { NavLink } from "react-router-dom";
import "./PackvideoCard.css";
export default function PackvideoCard({ packvideo }) {
  const { setSelectedSubsId } = React.useContext(GlobalContext); // Appel du hook en dehors du bloc conditionnel

  if (!packvideo) return null; // Condition de rendu après l'appel du hook

  const { name, description, price, token_amount, id } = packvideo;

  const maxLength = 300; // Nombre maximum de caractères à afficher
  const truncatedDescription =
    description && description.length > maxLength
      ? description.substring(0, maxLength) + "..."
      : description;

  const descriptionArray = truncatedDescription
    ? truncatedDescription.split(".").filter(Boolean)
    : [];

  const handleEnSavoirPlusClick = () => {
    setSelectedSubsId(id);
  };

  return (
    <div className="packVideo-card parent-container">
    <Card style={{ width: "18rem" }} className="h-100">
      <CardBody
        style={{
          backgroundColor: "#F3778F",
          borderRadius: "20px",
          padding: "2rem"
        }}
      >
        <CardTitle tag="h5" style={{ color: "#ffffff", textAlign: "center" }}>
          <b>{name}</b>
        </CardTitle>
        <div
          className="packvideo-details"
          style={{
            backgroundImage: `url(${rounded_back})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            padding: "43px",
          }}
        >
          <h3
            className="plan-price padding-b-15"
            style={{ color: "#F3778F", fontWeight: 600 }}
          >
            {price} €
          </h3>
        
        </div>
        <p
            className="token-amount"
            style={{
              color: "#ffffff",
              fontSize: "14px",
              textAlign: "center",
            }}
          >
            {token_amount} jetons
          </p>
        <CardSubtitle
          className="mb-2"
          tag="h6"
          style={{
            color: "#ffffff",
            textAlign: "left",
            padding: "30px 0 20px 0",
            fontSize: "14px"
          }}
        >
          {descriptionArray.map((line, index) => (
            <React.Fragment key={index}>
              <span style={{ color: "#ffffff" }}>✔</span>
              {line.trim()}
              <br />
            </React.Fragment>
          ))}
        </CardSubtitle>
        <center>
          <NavLink
            to={`/public/pack/g/${id}`}
            className="btn btn-sm abonner"
            onClick={handleEnSavoirPlusClick}
          >
           J’achète !
          </NavLink>
        </center>
      </CardBody>
    </Card>
    </div>
  );
}

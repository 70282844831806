import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import GlobalContext from "../../../../Context/GlobalContext";
import BoutiqueEnligne from "./Boutique/Boutique";
import Calendrier from "./Calendrier/Calendrier";
import Offres from "./Offres/Offres";
import Videos from "./Videos/Videos";
import onlineIcon from "../../../../Assets/images/Online-icon-angelique-story.png";
import offlineIcon from "../../../../Assets/images/sur_RDV_icone.png";
import LoadingPage from "../../../../components/Loading/LoadingComponent";
import styles from "./ProfileIntervenant.module.css";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ScrollToTop from "../../../../Utils/ScrollToTop";

const TypographyWithStyle = ({ variant, style, children }) => (
  <Typography variant={variant} style={style}>
    {children}
  </Typography>
);

const SectionHeader = ({ title, subtitle, isSmallScreen }) => (
  <>
    <TypographyWithStyle
      variant="h4"
      style={{
        color: "var(--taupe-fonc, #66534D)",
        textAlign: "center",
        fontFamily: "Majetto",
        fontSize: isSmallScreen ? "1.5rem" : "3.5rem",
        fontStyle: "normal",
        fontWeight: 400,
        textTransform: "uppercase",
      }}
    >
      {title}
    </TypographyWithStyle>
    <TypographyWithStyle
      variant="h3"
      style={{
        color: "var(--taupe-fonc, #66534D)",
        textAlign: "center",
        fontFamily: "Roboto",
        fontSize: isSmallScreen ? "1.1rem" : "2.5rem",
        fontStyle: "normal",
        fontWeight: 400,
      }}
    >
      {subtitle}
    </TypographyWithStyle>
  </>
);

function ProfilIntervenant() {
  const context = React.useContext(GlobalContext);

  const [userData, setUserData] = React.useState(null);
  const [categories, setCategories] = React.useState(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const location = useLocation();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const userProfile =
          JSON.parse(localStorage.getItem('selectedIntervenantProfile'));

        const categoryData =
           JSON.parse(localStorage.getItem('categories'));

        if (userProfile && categoryData) {
          setUserData(userProfile);
          setCategories(categoryData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Only fetch data if it's not available
    if (!userData || !categories) {
      fetchData();
    }
  }, [userData, categories]);
console.log(userData)
  React.useEffect(() => {
    // Clear selected profile on unmount
    return () => {
      context.setSelectedIntervenantProfile(null);
    };
  }, [context.setSelectedIntervenantProfile]);

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const message = queryParams.get('message');

    // Show toast only when the 'message' changes
    if (message === 'canceled') {
      toast.info('Votre commande a été annulée');
    } else if(message === 'success') {
      toast.success('Votre commande a été valider');
    }
  }, [location.search]);
    
  if (!userData || !categories) {
    return <LoadingPage />;
  }

  return (
    <div>
      <section className={styles["section-description"]}>
        <Grid container spacing={2} alignItems="center">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          
          <Grid item xs={12} sm={6}>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  userData[0].availability_user === 1 ? onlineIcon : offlineIcon
                }
                alt={userData[0].availability_user === 1 ? "Online" : "Offline"}
                className={styles["availability-icon"]}
                style={{
                  position: "absolute",
                  padding: "10px 0px 0px 10px",
                  zIndex: 1,
                }}
              />
              <img alt="Sample" src={`${process.env.REACT_APP_APIURL}/storage/workshop/${userData[0].photo_user}`} />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={styles["description"]}>
              <TypographyWithStyle
                variant="h4"
                style={{
                  color: "var(--taupe-fonc, #66534D)",
                  textAlign: "left",
                  fontFamily: "Majetto",
                  fontSize: isSmallScreen ? "1.5rem" : "2.5rem",
                  fontStyle: "normal",
                  fontWeight: 400,
                  textTransform: "uppercase",
                }}
              >
                {userData[0].prenom_user}
              </TypographyWithStyle>

              <TypographyWithStyle
                variant="p"
                style={{
                  color: "var(--taupe-fonc, #66534D)",
                  textAlign: "left",
                  fontFamily: "Roboto",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                {userData[0].description_user}
              </TypographyWithStyle>

              <div className={styles["div-speciality"]}>
                <span className={styles["speciality-span"]}>
                  {userData[0].main_speciality_user}
                </span>
                {userData[0].specialities.map((specialty) => (
                  <span
                    className={styles["speciality-span"]}
                    key={specialty.id}
                  >
                    {specialty.specialty}
                  </span>
                ))}
              </div>
            </div>
          </Grid>
        </Grid>
      </section>

      <section className={styles["bg-section-boutique"]}>
        <BoutiqueEnligne />
      </section>

      <section className={styles["section-reservation"]}>
        <SectionHeader
          title="Réservation"
          subtitle="Ateliers"
          isSmallScreen={isSmallScreen}
        />
        <Calendrier userData={userData[0]} />
      </section>

      <section style={{ padding: "0px 10% 10% 10%" }}>
        <SectionHeader
          title="Consultation"
          subtitle="Audio, Visio & Chat (DM)"
          isSmallScreen={isSmallScreen}
        />
        <Offres userData={userData[0]} Categories={categories} />
      </section>

      <section
        className={styles["section-videos"]}
        style={{ padding: "0px 10% 10% 10%" }}
      >
        <SectionHeader title="mes vidéos" isSmallScreen={isSmallScreen} />
        <p style={{ textAlign: "center" }}>
          Homines est dierum quod consilium consilium idem gratulatio consilium
          Rei causa reprehendant decreverim pertinerent dignitatem mea causa
          quod consilium hominis Rei.
        </p>
        <Videos userData={userData[0]} />
      </section>
    </div>
  );
}

export default ProfilIntervenant;

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    // Scroll to top every time the location changes, including when navigating backward
    window.scrollTo(0, 0);
  }, [location.key]); // Use location.key to ensure it triggers on all navigation actions

  return null;
};

export default ScrollToTop;

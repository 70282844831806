import React from "react";
import {
  Collapse,
  Navbar,
  Nav,
  NavLink,
  NavItem,
  NavbarToggler,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Grid } from "@mui/material";
import classes from "./IndexNavbar.module.css";
import separator from "../../Assets/images/separator.png";
import logo from "../../Assets/images/Logo.png";
import GlobalContext from "../../Context/GlobalContext";
import { useNavigate, Link } from "react-router-dom";
import md5 from "md5";
import avatar from "../../Assets/images/fa-regular_user.png";
export default function IndexNavbar() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenUserDropdown, setIsOpenUserDropdown] = React.useState(false);
  const context = React.useContext(GlobalContext);

  const [identifierHash, setIdentifierHash] = React.useState(
    context.client
      ? md5(
          (context.client.prenom_client + " " + context.client.nom_client)
            .toLowerCase()
            .trim()
        )
      : null
  );

  const navigate = useNavigate();
  const toggle = () => setIsOpen(!isOpen);

  const onSettingsClick = () => {
    navigate("/profile");
  };

  const handleItemClick = (e, pathname) => {
    e.preventDefault();
    navigate(pathname);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={8}>
        <Navbar light expand="xxl">
          {/* Logo */}
          <Nav>
            <Link to="/">
              <img alt="logo" src={logo} className="logo" />
            </Link>
          </Nav>
          <NavbarToggler onClick={toggle} className={classes["navbar-toggler-custom"]} />
          <Collapse isOpen={isOpen} navbar>
            {/* Navbar Items */}
            <Nav className="mx-auto" navbar /* style={{ gap: "20px" }} */>
              <NavItem
                className={`mx-auto ${classes.navItem}`}
                onClick={() => (window.location.href = "/")}>
                Accueil
              </NavItem>

              <NavItem
                className={`mx-auto ${classes.navItem}`}
                onClick={(e) => handleItemClick(e, "/intervenants")}>
                Intervenants
              </NavItem>
              <NavLink
                href="https://angeliquehirmancestory.fr/"
                target="_blank"
                className={`mx-auto ${classes.navItem}`}>
                Boutique
              </NavLink>

              <NavItem
                className={`mx-auto ${classes.navItem}`}
                onClick={(e) => handleItemClick(e, "/contact")}>
                Contact
              </NavItem>
            </Nav>
            {/* Login Elements */}
            <Nav
              className="mx-auto d-flex justify-content-space-around align-items-center"
              navbar>
              {context.isLoggedIn ? (
                <Dropdown
                  isOpen={isOpenUserDropdown}
                  toggle={() => setIsOpenUserDropdown(!isOpenUserDropdown)}>
                  <DropdownToggle nav>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}>
                      <img
                        src={avatar}
                        alt="avatar"
                        className={classes.avatar}
                      />
                      <span className={classes.name}>
                        {context.client
                          ? `Bonjour ${context.client.prenom_client} ${context.client.nom_client}`
                          : ""}
                      </span>
                      <i className="fa-solid fa-caret-down"></i>{" "}
                      {/* Arrow icon */}
                    </div>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={onSettingsClick}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <i className="fa-solid fa-gear"></i>
                        <h5
                          className={classes.dropdownItemText}
                          style={{ margin: "10px" }}>
                          Espace client
                        </h5>
                      </div>
                    </DropdownItem>
                    <DropdownItem onClick={context.logout}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <i className="fa-solid fa-arrow-right-from-bracket"></i>
                        <h5
                          className={classes.dropdownItemText}
                          style={{ color: "red", margin: "10px" }}>
                          Déconnecter
                        </h5>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              ) : (
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "15px",
                    marginLeft: "20px",
                    whiteSpace: "nowrap",
                  }}>
                  <NavItem
                    className={classes.connectItem}
                    onClick={(e) => handleItemClick(e, "/login")}>
                    <img
                      src={avatar}
                      className={classes["icon-action"]}
                      alt="Se connecter"
                    />
                    <span className="custom-margin-left">Se connecter</span>
                  </NavItem>
                  <NavItem>
                    <img
                      src={separator}
                      className={classes["separator"]}
                      alt="Separator"
                      aria-hidden="true"
                    />
                  </NavItem>
                  <NavItem
                    className={classes.signupItem}
                    onClick={(e) => handleItemClick(e, "/signup")}>
                    S'inscrire
                  </NavItem>
                </div>
              )}
              <NavItem
                className={classes.abonner_btn}
                onClick={(e) => handleItemClick(e, "/subscriptions")}>
                S'abonner
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </Grid>
    </Grid>
  );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_sidebar__V0gM- {
    background-color: #fbebee;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 50px;
  }`, "",{"version":3,"sources":["webpack://./src/Pages/IntervenantsPage/Sidebar/Sidebar.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;EACrB","sourcesContent":[".sidebar {\n    background-color: #fbebee;\n    border-radius: 20px;\n    padding: 20px;\n    margin-bottom: 50px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `Sidebar_sidebar__V0gM-`
};
export default ___CSS_LOADER_EXPORT___;

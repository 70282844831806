import React, { useState } from "react";
import axios from "../../../../Utils/axios";
import { Button, Typography, Alert } from "@mui/material";
import { Form, FormGroup, Col, Input } from "reactstrap";
import pin from "../../../../Assets/images/pin-icon-story.png";
import phone from "../../../../Assets/images/phone-icon-story.png";
import mail from "../../../../Assets/images/mail-icon-story.png";
import facebook from "../../../../Assets/images/icon _facebook_story.png";
import instagram from "../../../../Assets/images/icon _instagram_story.png";
import "./ContactForm.css";
import "bootstrap/dist/css/bootstrap.min.css";
//import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import "bootstrap/dist/css/bootstrap.min.css";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    tel: "",
    email: "",
    content: "",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/public/s/c/contact", formData);
      console.log(response.data); // Assuming your backend responds with a message
      // Handle success or display a success message to the user
      setFormSubmitted(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error(error);
      // Handle error or display an error message to the user
    }
  };

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div>
      <section className="bg-section">
        <div className="class-row">
          <div className="col-6 contact-form">
            <div className="mt-5-margin adjust-form-class">
            <Typography
              variant="h2"
              sx={{
                color: "#F3778F",
                textAlign: "left",
                fontFamily: "Majetto",
                fontSize: {
                  xs: "1.5rem",
                  sm: "1.5rem",
                  md: "1.5rem",
                  lg: "1.8rem",
                  xl: "2.5rem",
                },
                fontStyle: "normal",
                fontWeight: 500,
              }}>
               Envoyer
               <br></br> un Message
            </Typography>
              <br></br>
              <Form onSubmit={handleSubmit}>

                {/* New Service Type Dropdown */}
                <FormGroup row>
                  <Col xs={6} sm={6} md={12} lg={12} xl={12}>
                    <Input
                      id="service_type"
                      name="service_type"
                      type="select"
                      onChange={handleChange}
                      value={formData.service_type}
                      className="responsive-select"
                      style={{
                        boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                      }}>
                      <option value="">Sélectionnez le type de service</option>
                      <option value="Service client">Service client</option>
                      <option value="Service commercial">
                        Service commercial
                      </option>
                      <option value="Service réclamation">
                        Service réclamation
                      </option>
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col sm={12}>
                    <Input
                      id="name"
                      name="name"
                      placeholder="Prénom/Nom"
                      type="text"
                      onChange={handleChange}
                      className="custom-input" // Add this custom class
                      value={formData.name}
                      style={{
                        boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                      }}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Col sm={12}>
                    <Input
                      id="tel"
                      name="tel"
                      placeholder="Téléphone"
                      type="phone"
                      onChange={handleChange}
                      className="custom-input" // Add this custom class
                      value={formData.tel}
                      style={{
                        boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                      }}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Col sm={12}>
                    <Input
                      id="email"
                      name="email"
                      placeholder="Email"
                      type="email"
                      onChange={handleChange}
                      className="custom-input" // Add this custom class
                      value={formData.email}
                      style={{
                        boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                      }}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Col sm={12}>
                    <Input
                      id="content"
                      name="content"
                      type="textarea"
                      placeholder="Votre message"
                      onChange={handleChange}
                      className="custom-input" // Add this custom class
                      value={formData.content}
                      style={{
                        boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                      }}
                    />
                  </Col>
                </FormGroup>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: "#F3778F",
                    color: "white",
                    fontSize: {
                      xs: "13px", // Font size for extra small screens
                      sm: "14px", // Font size for small screens and up
                      md: "15px", // Font size for medium screens and up
                      lg: "14px", // Font size for large screens and up
                      xl: "18px", // Font size for extra-large screens and up
                    },
                    borderRadius: "8px", // Rounded corners
                  }}>
                  Envoyer !
                </Button>
              </Form>

              {formSubmitted && (
                <div style={{ marginTop: "20px" }}>
                  <Alert severity="success">
                    Message envoyée avec succès !
                  </Alert>
                  {refreshPage()}{" "}
                  {/* Refresh the page when alert is displayed */}
                </div>
              )}
            </div>
          </div>

          <div className="col-6 class-col-boudoir">
            <div className="container adjust-coordonnes-class">
              <Typography
                variant="h4"
                style={{
                  color: "var(--taupe-fonc, white)",
                  textAlign: "left",
                  fontFamily: "Majetto",
                  fontSize: "2.5vw",
                  fontStyle: "normal",
                  fontWeight: 400,
                  textTransform: "uppercase",
                  marginBottom: "50px",
                }}>
                CONTACTEZ<br></br>NOUS
              </Typography>

              <div>
                <p style={{ color: "white" }}>
                  <img src={pin} className="icon-box" alt="angelique-story" />
                  17 Rue de Broglie , <br></br>66330 Cabestany (Mas Guérido)
                </p>
                <p style={{ color: "white" }}>
                  <img src={phone} className="icon-box" alt="angelique-story" />{" "}
                  06 25 74 04 93
                </p>
                <p style={{ color: "white" }}>
                  <img src={mail} className="icon-box" alt="angelique-story" />{" "}
                  echoppe@angeliquehirmancestory.fr
                </p>
              </div>
              <div style={{ paddingTop: "30px" }}>
                <a
                  href="https://www.facebook.com/angeliquehirmance/"
                  target="_blank">
                  <img
                    src={facebook}
                    className="icon-box"
                    alt="angelique-story"
                  />
                </a>{" "}
                <a
                  href="https://www.instagram.com/angeliquehirmance/"
                  target="_blank">
                  <img
                    src={instagram}
                    className="icon-box"
                    alt="angelique-story"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-section-mobile">
        <div className="row contact-form">
          <div className="container mt-5 adjust-form-class">
          <Typography
              variant="h2"
              sx={{
                color: "#F3778F",
                textAlign: "left",
                fontFamily: "Majetto",
                fontSize: {
                  xs: "1.5rem",
                  sm: "2rem",
                  md: "2.5rem",
                  lg: "2.5rem",
                  xl: "2.5rem",
                },
                fontStyle: "normal",
                fontWeight: 500,
              }}>
               Envoyer
               <br></br> un Message
            </Typography>
            <br></br>
            <Form onSubmit={handleSubmit}>
              {/* New Service Type Dropdown */}
              <FormGroup row>
                <Col sm={12}>
                  <Input
                    id="service_type"
                    name="service_type"
                    type="select"
                    onChange={handleChange}
                    value={formData.service_type}
                    style={{
                      boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                    }}>
                    <option value="">Sélectionnez le type de service</option>
                    <option value="Service client">Service client</option>
                    <option value="Service commercial">
                      Service commercial
                    </option>
                    <option value="Service réclamation">
                      Service réclamation
                    </option>
                  </Input>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  <Input
                    id="name"
                    name="name"
                    placeholder="Nom complet"
                    type="text"
                    onChange={handleChange}
                    value={formData.name}
                    style={{
                      boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  <Input
                    id="email"
                    name="email"
                    placeholder="Email"
                    type="email"
                    onChange={handleChange}
                    value={formData.email}
                    style={{
                      boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12}>
                  <Input
                    id="content"
                    name="content"
                    type="textarea"
                    placeholder="Votre message"
                    onChange={handleChange}
                    value={formData.content}
                    style={{
                      boxShadow: "rgb(185 200 255 / 7%) -7px 14px 20px 20px",
                    }}
                  />
                </Col>
              </FormGroup>
              
              <Button
                type="submit"
                style={{ backgroundColor: "#8d7268", color: "white" }}>
                Envoyer !
              </Button>
            </Form>
            {formSubmitted && (
              <div style={{ marginTop: "20px" }}>
                <Alert severity="success">Message envoyée avec succès !</Alert>
                {refreshPage()} {/* Refresh the page when alert is displayed */}
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="class-col-boudoir">
            <div className="container mt-5 adjust-coordonnes-class">
              <Typography
                variant="h4"
                style={{
                  color: "var(--taupe-fonc, white)",
                  textAlign: "left",
                  fontFamily: "Majetto",
                  fontSize: {
                    xs: "5.5rem",
                    sm: "5.5rem",
                    md: "5.5rem",
                    lg: "8.5rem",
                    xl: "8.5rem",
                  },
                  fontStyle: "normal",
                  fontWeight: 400,
                  textTransform: "uppercase",
                  marginBottom: "50px",
                }}>
                CONTACTEZ<br></br>NOUS
              </Typography>
              <p style={{ color: "#F6D1D8", paddingBottom: "30px" }}>
                Contactez-nous pour plus d'information
              </p>
              <div>
                <p style={{ color: "white" }}>
                  <img src={pin} className="icon-box" alt="angelique-story" />{" "}
                  17 Rue de Broglie , 66330 Cabestany (Mas Guérido)
                </p>
                <p style={{ color: "white" }}>
                  <img src={phone} className="icon-box" alt="angelique-story" />{" "}
                  06 25 74 04 93
                </p>
                <p
                  style={{
                    color: "white",
                    fontSize: {
                      xs: "6vw", // Font size for extra small screens
                      sm: "3.5vw", // Font size for small screens
                      md: "3.5vw", // Font size for medium screens
                      lg: "3.5vw", // Font size for large screens
                      xl: "3.5vw", // Font size for extra large screens
                    },
                  }}>
                  <img src={mail} className="icon-box" alt="angelique-story" />{" "}
                  echoppe@angeliquehirmancestory.fr<br></br>story.fr
                </p>
              </div>
              <div style={{ paddingTop: "30px" }}>
                <img
                  src={facebook}
                  className="icon-box"
                  alt="angelique-story"
                />{" "}
                <img
                  src={instagram}
                  className="icon-box"
                  alt="angelique-story"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;

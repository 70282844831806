import React, { useState } from "react";
import { Typography, useTheme } from '@mui/material';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import thumbnail_yt from "../../../../Assets/images/thumbnail_yt.png";
import "./VideoExplication.css";

function VideoExplication() {
    const theme = useTheme();

    // State to manage modal visibility and active video ID
    const [showModal, setShowModal] = useState(false);
    const [activeVideoId, setActiveVideoId] = useState(null);

    // Function to open modal and set the video ID
    const handleThumbnailClick = (videoId) => {
        setActiveVideoId(videoId);
        setShowModal(true);
    };

    // Function to close the modal
    const handleCloseModal = () => {
        setShowModal(false);
        setActiveVideoId(null);  // Clear active video when modal closes
    };

    return (
        <div>
            <section className="bg-section-videoExp">
                <div>
                    <Typography
                        variant="h4"
                        sx={{
                            color: 'var(--taupe-fonc, #66534D)',
                            textAlign: 'center',
                            fontFamily: 'Majetto',
                            fontSize: {
                                xs: '6vw', // Font size for extra small screens
                                sm: '3.5vw', // Font size for small screens
                                md: '3.5vw', // Font size for medium screens
                                lg: '3.5vw', // Font size for large screens
                                xl: '3.5vw' // Font size for extra large screens
                            },
                            fontStyle: 'normal',
                            fontWeight: 400,
                            textTransform: 'uppercase',
                        }}
                    >
                        Guide d'utilisation
                    </Typography>
                    <Typography
                        variant="h4"
                        style={{
                            color: 'var(--taupe-fonc, #66534D)',
                            textAlign: 'center',
                            fontFamily: 'Roboto',
                            fontSize: '22px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                        }}
                    >
                        Comment Utiliser Notre Plateforme de Voyance
                    </Typography>
                    <br></br>

                    {/* Videos Grid */}
                    <div className="videos-container" style={{ display: 'flex', justifyContent: 'center', gap: '20px', flexWrap: 'wrap' }}>
                        {[
                            { videoId: 'VIDEO_ID_1', title: 'Video 1 Title' },
                            { videoId: 'VIDEO_ID_2', title: 'Video 2 Title' },
                            { videoId: 'VIDEO_ID_3', title: 'Video 3 Title' },
                            { videoId: 'VIDEO_ID_4', title: 'Video 4 Title' }
                        ].map((video, index) => (
                            <div key={index} className="video-wrapper" style={{ width: '300px', textAlign: 'center' }}>
                                <div className="thumbnail-wrapper" style={{ cursor: 'pointer' }} onClick={() => handleThumbnailClick(video.videoId)}>
                                    <img src={thumbnail_yt} alt="thumbnail" style={{ width: '100%' }} />
                                </div>
                                <Typography variant="subtitle1" sx={{ color: '#66534D', fontWeight: 500 }}>
                                    {video.title}
                                </Typography>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Modal for displaying the video */}
            <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {activeVideoId && (
                        <div className="video-responsive">
                            <iframe
                                width="100%"
                                height="400"
                                src={`https://www.youtube.com/embed/${activeVideoId}`}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default VideoExplication;

import React from 'react'
import classes from './LoadingComponent.module.css'
import storyLogo from '../../Assets/images/logo-footer-story.png'

const LoadingPage = () => {
    return (
        <div style={{ minHeight: '100vh' }}>
            <div className={classes.mainContainer} style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                <div>
                    <img className={classes.logoImage} alt="loading" src={storyLogo} />
                </div>
            </div>
        </div>

    )
}

export default LoadingPage

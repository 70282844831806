import React from "react";
import {Typography, useTheme } from '@mui/material';
import { useNavigate, NavLink } from "react-router-dom";
import "./OffersOverview.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import 'bootstrap/dist/css/bootstrap.min.css';



function OffersOverview() {

    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <div>
        <section className="bg-section-1">
            <div>
                <Typography
                variant="h4"
                sx={{
                    color: '#F3778F',
                    textAlign: 'center',
                    fontFamily: 'Majetto',
                    fontSize: {
                        xs: '6vw', // Font size for extra small screens
                        sm: '3.5vw', // Font size for small screens
                        md: '3.5vw', // Font size for medium screens
                        lg: '3.5vw', // Font size for large screens
                        xl: '3.5vw' // Font size for extra large screens
                    },
                    fontStyle: 'normal',
                    fontWeight: 400,
                    textTransform: 'uppercase',
                   
                }}
                >
                Boutique e-commerce
                </Typography>
                <Typography
                variant="h4"
                style={{
                    color: 'var(--taupe-fonc, #66534D)',
                    textAlign: 'center',
                    fontFamily: 'Roboto',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    
                }}
                >
                    <br></br>
                Nous avons hâte de vous retrouver dans notre boutique<br></br>
                N'hésitez plus et cliquez sur le bouton
                </Typography>
           
                <div className="div-more">
                    <center> <a href="https://angeliquehirmancestory.fr/" target="_blank"><button className="btn-more" >Je Découvre !</button></a></center>
                </div>
            </div>
        </section>
            
            </div>
            
    );
}

export default OffersOverview;

import React from 'react'
import { Row } from 'reactstrap'
import classes from './Home.module.css'
import Contact from './Pages/Contact/ContactForm';
import OffersOverview from './Pages/OffersOverview/OffersOverview';
import Header from './Pages/Header/Header';
import PackvideoHome from './Pages/PackvideoPage/PackvideoHome/PackvideoHome';
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import NumerologyCalculator from './Pages/NumerologyCalculator/NumerologyCalculator'
//import SubscriptionsHome from './Pages/SubscriptionsPage/SubscriptionsHome/SubscriptionsHome';
//import TitleSection from "./Pages/SubscriptionsPage/Subscriptions/Components/SubscriptionCard/SubscriptionTitle";
import CombinedSubscriptionSection from "./Pages/SubscriptionsPage/Subscriptions/Components/SubscriptionCard/CombinedSubscriptionSection";
import CombinedPackvideoSection from "./Pages/PackvideoPage/Packvideo/Components/PackvideoCard/CombinedPackvideoSection";
import VideoExplication from "./Pages/VideoExplication/VideoExplication";

export default function Home() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const message = params.get('message');

  React.useEffect(() => {
    if (message) {
      toast.info(message);
    }
  }, [message]);

  return (
    <Row className={classes.container} >
       
        <Header />
        <NumerologyCalculator />
        {/* <TitleSection/>
        <SubscriptionsHome /> */}
        <CombinedSubscriptionSection/>
        <OffersOverview />
        <CombinedPackvideoSection /> {/* Ajoutez le composant PackvideoHome ici */}
        <Contact />
    </Row>
  )
}
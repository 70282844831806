import React from 'react';
import './FilterBar.css';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

function FilterBar(props) {
  const [openStatus, setOpenStatus] = React.useState('0');
  const [openSpecialities, setOpenSpecialities] = React.useState('0')
  const [isOnline, setIsOnline] = React.useState(true);
  const [isOffline, setIsOffline] = React.useState(true);
  const [selectedSpecialities, setSelectedSpecialities] = React.useState([]);

  React.useEffect(() => {
    let filteredUsers = props.userData;

    if (!(isOnline && isOffline)) {
      filteredUsers = filteredUsers.filter((user) => {
        if (isOnline) {
          return user.availability_user === 1;
        } else if (isOffline) {
          return user.availability_user === 0;
        }
        return false;
      });
    }

    if (selectedSpecialities.length > 0) {
      filteredUsers = filteredUsers.filter((user) =>
        selectedSpecialities.some((speciality) =>
          user.specialities.some((userSpeciality) => userSpeciality.id === speciality)
        )
      );
    }
    props.onFilter(filteredUsers);
  }, [isOnline, isOffline, selectedSpecialities]);

  const toggleStatus = (id) => {
    setOpenStatus((prevState) => (prevState === id ? null : id));
  };

  const toggleSpecialities = (id) => {
    setOpenSpecialities((prevState) => (prevState === id ? null : id));
  };

  const handleStatusChange = (value) => {
    if (value === 'online') {
      setIsOnline(!isOnline);
    } else if (value === 'offline') {
      setIsOffline(!isOffline);
    }
  };

  const handleSpecialityChange = (specialityId) => {
    const updatedSpecialities = selectedSpecialities.includes(specialityId)
      ? selectedSpecialities.filter((id) => id !== specialityId)
      : [...selectedSpecialities, specialityId];
    setSelectedSpecialities(updatedSpecialities);
  };

  const FilterItem = ({ id, label, checked, onChange }) => {
    return (
      <ListItem key={id} disablePadding>
        <ListItemButton role={undefined} onClick={() => onChange(id)} dense>
          <ListItemIcon>
            <Checkbox edge="start" checked={checked} tabIndex={-1} disableRipple />
          </ListItemIcon>
          <ListItemText primary={label} />
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <div className="filter-bar">
      <h5>Filtres</h5>
      <Accordion open={openStatus} toggle={toggleStatus} className='mb-3'>
        <AccordionItem>
          <AccordionHeader targetId="1">Disponibilité</AccordionHeader>
          <AccordionBody accordionId="1">
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              <FilterItem
                id="online"
                label="En ligne"
                checked={isOnline}
                onChange={handleStatusChange}
              />
              <FilterItem
                id="offline"
                label="Sur RDV"
                checked={isOffline}
                onChange={handleStatusChange}
              />
            </List>
          </AccordionBody>
        </AccordionItem>
        </Accordion>
        <Accordion open={openSpecialities} toggle={toggleSpecialities}>
          <AccordionItem>
          <AccordionHeader targetId="2">Specialité</AccordionHeader>
          <AccordionBody accordionId="2">
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {props.specialities.map((speciality) => {
                const labelId = speciality.id;
                return (
                  <ListItem
                    key={speciality.id}
                    disablePadding
                  >
                    <ListItemButton role={undefined} onClick={() => handleSpecialityChange(speciality.id)} dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={selectedSpecialities.indexOf(speciality.id) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={speciality.specialty} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </AccordionBody>
          </AccordionItem>
        </Accordion>
    </div>
  );
}

export default FilterBar;

import {Typography,useTheme  } from '@mui/material';
import "./SubscriptionTitle.css";



export default function SubscriptionTitle(){
    const theme = useTheme();

    return(
        <div className='subs-div'>
            <Typography
                variant="h4"
                sx={{
                    color: '#F3778F',
                    textAlign: 'center',
                    fontFamily: 'Majetto',
                    fontSize: {
                        xs: '6vw', // Font size for extra small screens
                        sm: '3.5vw', // Font size for small screens
                        md: '3.5vw', // Font size for medium screens
                        lg: '3.5vw', // Font size for large screens
                        xl: '3.5vw' // Font size for extra large screens
                    },
                    fontStyle: 'normal',
                    fontWeight: 400,
                    textTransform: 'uppercase',
                }}
            >
                Abonnez-vous
            </Typography>
            <Typography
                variant="h4"
                sx={{
                    color: 'var(--taupe-fonc, #66534D)',
                    textAlign: 'center',
                    fontFamily: 'Roboto',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                }}
            >
                Ne passez pas à côté ! Abonnez-vous dès maintenant pour un accès illimité à nos contenus exclusifs chaque mois
            </Typography>
        </div>
    );
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-section-videoExp{
    background-repeat: no-repeat;
    background-position: center;
    padding: 3%;
    margin-top: 6%;
  }

  /*.video-responsive {
    overflow: hidden;
   padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }*/
  
  .video-responsive iframe {
    top: 0;
    /*position: absolute;*/
  }

  .videos-container{
    margin-top: 50px;
  }
  `, "",{"version":3,"sources":["webpack://./src/Pages/Home/Pages/VideoExplication/VideoExplication.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,2BAA2B;IAC3B,WAAW;IACX,cAAc;EAChB;;EAEA;;;;;IAKE;;EAEF;IACE,MAAM;IACN,sBAAsB;EACxB;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":[".bg-section-videoExp{\n    background-repeat: no-repeat;\n    background-position: center;\n    padding: 3%;\n    margin-top: 6%;\n  }\n\n  /*.video-responsive {\n    overflow: hidden;\n   padding-bottom: 56.25%;\n    position: relative;\n    height: 0;\n  }*/\n  \n  .video-responsive iframe {\n    top: 0;\n    /*position: absolute;*/\n  }\n\n  .videos-container{\n    margin-top: 50px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect, useRef } from 'react';
import {
  CssBaseline,
  Container,
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  useTheme,
  ThemeProvider
} from "@mui/material";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import backgroundCalcul from "../../../../Assets/images/bg-calcul-numerologique-angelique-hirmance-story.png";
import { createTheme } from "@mui/material/styles"; // Import createTheme
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import { FormControl, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

const NumerologyCalculator = () => {
  const [showResults, setShowResults] = useState(false);
  const [showLoginMessage, setShowLoginMessage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [inputValueSignif, setInputValueSignif] = useState("");
  const [result, setResult] = useState("");
  const [resultPath, setresultPath] = useState(""); // For the result path
  const [clientData, setClientData] = useState(null);
  const [showAbonnementMessage, setShowAbonnementMessage] = useState(false);
  const [showMeaningMessage, setShowMeaningMessage] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null); // Tracks the selected checkbox
  const [showResultsFinal, setShowResultsFinal] = useState(false); // State to hold final result for the popup
  const [customText, setCustomText] = useState(false);
  const [shouldFocus, setShouldFocus] = useState(true);

  const handleCloseLogin = () => setShowLoginMessage(false);
  const handleCloseAbonnement = () => setShowAbonnementMessage(false);
  const handleCloseMeaning = () => setShowMeaningMessage(false);

  const letterValues = {
    '1': ['A', 'J', 'S', 'É', 'È', 'Ê' ,'Ç'],
    '2': ['B', 'K', 'T', 'Ö'],
    '3': ['C', 'L', 'U', 'Ù', 'Ú', 'Û'],
    '4': ['D', 'M', 'V'],
    '5': ['E', 'N', 'W', 'Ë', 'Î'],
    '6': ['F', 'O', 'X', 'Ä', 'Ô'],
    '7': ['G', 'P', 'Y'],
    '8': ['H', 'Q', 'Z', 'Ü'],
    '9': ['I', 'R', 'À', 'Â', 'Ï']
  };

  const customTextMap = {
    1: "C'est un nouveau départ.",
    2: "Partenariat et équilibre.",
    3: "Créativité et expression.",
    4: "Travail acharné et détermination.",
    5: "Liberté et aventures.",
    6: "Amour et famille.",
    7: "Réflexion et introspection.",
    8: "Pouvoir et réussite.",
    9: "Humanitarisme et idéal.",
    // Add more mappings as needed
  };

  const customTextMapNom = {
    1: "C'est un nouveau départ pour votre nom.",
    2: "Votre nom est lié au partenariat et à l'équilibre.",
    3: "Votre nom favorise la créativité et l'expression.",
    4: "Votre nom inspire le travail acharné et la détermination.",
    5: "Liberté et aventures Nom.",
    6: "Amour et famille Nom.",
    7: "Réflexion et introspection Nom.",
    8: "Pouvoir et réussite Nom.",
    9: "Humanitarisme et idéal Nom.",
    };
  
  const customTextMapEntreprise = {
    1: "C'est un nouveau départ pour votre entreprise.",
    2: "Votre entreprise est liée au partenariat et à l'équilibre.",
    3: "Votre entreprise favorise la créativité et l'expression.",
    4: "Votre entreprise inspire le travail acharné et la détermination.",
    5: "Liberté et aventures entreprise.",
    6: "Amour et famille entreprise.",
    7: "Réflexion et introspection entreprise.",
    8: "Pouvoir et réussite entreprise.",
    9: "Humanitarisme et idéal entreprise.",
    };
  
  const customTextMapAdresse = {
    1: "Votre adresse commence un nouveau chapitre.",
    2: "Votre adresse symbolise l'équilibre.",
    3: "Votre adresse est un lieu de créativité.",
    4: "Votre adresse est un lieu de stabilité et de détermination.",
    5: "Liberté et aventures adresse.",
    6: "Amour et famille adresse.",
    7: "Réflexion et introspection adresse.",
    8: "Pouvoir et réussite adresse.",
    9: "Humanitarisme et idéal adresse.",
    };
  
  // Handle checkbox selection
  const handleCheckboxChange = (event) => {
    const option = event.target.name; // Get the name of the checkbox
    setSelectedOption(option);
  
    // Set the custom text based on the selected option and result
    let text = "";
    const calculatedNumber = result; // Assuming result is your calculated numerology number
  
    if (option === "Prénom / Nom") {
      text = customTextMapNom[calculatedNumber];
    } else if (option === "Entreprise") {
      text = customTextMapEntreprise[calculatedNumber];
    } else if (option === "Adresse") {
      text = customTextMapAdresse[calculatedNumber];
    }
  
    setCustomText(text); // Update the custom text state
  
    // Show the results dialog
    if (text) {
      setShowResultsFinal(true); // Open the dialog
      setSelectedOption(null);
    }
  };

  const handleButtonClick = () => {
    console.log(`Input Value for ${selectedOption}:`, inputValue);
    // Add any additional logic you want to perform on button click
  };

  const handleClick = async () => {

    const token = localStorage.getItem("access_token_story");
    if (!token) {
      setShowLoginMessage(true);
      setLoading(false);
      return;
    }

    const response = await axios.get(`${process.env.REACT_APP_APIURL}/client/g/get/p/profile`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (response.data.status === "success") {
      setClientData(response.data.result);
      console.log('Client data:', response.data.result);
    } else {
      console.error("Failed to fetch client data.");
      setLoading(false);
      return;
    }

    // Check subscription and pack status
    if (response.data.result.etat_abn !== 1) {
      setShowAbonnementMessage(true);
      setLoading(false);
      return;
    }
    else{
      setShowMeaningMessage(true);
      setLoading(false);
      return;
    }
  }

  const handleCalculate = (inputValue) => {
    // Clean and uppercase the input, allowing letters and numbers
    const cleanedInput = inputValue
        .replace(/[^A-Z0-9éèçöùúûëîäôäôüàâïê]/gi, "")
        .toUpperCase();
    
    let numericValue = 0;
    let concatenatedResults = [];
    
    for (let i = 0; i < cleanedInput.length; i++) {
        const currentChar = cleanedInput.charAt(i);
        
        if (/\d/.test(currentChar)) {
            // If character is a digit, add its numeric value directly
            numericValue += parseInt(currentChar, 10);
        } else {
            // If character is a letter, look up its value in `letterValues`
            for (const [value, letters] of Object.entries(letterValues)) {
                if (letters.includes(currentChar)) {
                    numericValue += parseInt(value, 10);
                    break;
                }
            }
        }
    }
    
    // Save the first calculated sum
    concatenatedResults.push(numericValue);
    
    // Continue reducing the number until a single digit is achieved
    while (numericValue > 9) {
        numericValue = Array.from(String(numericValue), Number).reduce(
            (sum, num) => sum + num,
            0
        );
        if (numericValue > 9) {
            concatenatedResults.push(numericValue);
        }
    }
    
    // Log the calculated values here for debugging
    console.log("Intermediate numeric value:", numericValue);
    console.log("Concatenated results path:", concatenatedResults.join(" puis "));
    
    // Update states
    setResult(numericValue);
    setresultPath(concatenatedResults.join(" puis "));
    setShowResults(true);

    return { numericValue, concatenatedResults };
};


useEffect(() => {
  setShouldFocus(true); // Only set focus on initial load or after navigation
}, []);

  const [borderColor, setBorderColor] = useState("#f3778f"); // Couleur de la bordure par défaut

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
  
    // Mettre à jour la valeur saisie si nécessaire
    setInputValue(inputValue);
  };
  
  const handleInputChangeSignif= (event) => {
    const inputValueSignif = event.target.value;
  
    // Mettre à jour la valeur saisie si nécessaire
    setInputValueSignif(inputValueSignif);
  };

  const handleInputFocus = () => {
    setBorderColor("#f3778f"); // Changer la couleur de la bordure en bleu lorsque le champ de texte obtient le focus
  };

  const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: '#F3778F',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#F3778F',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#F3778F',
      },
      '&:hover fieldset': {
        borderColor: '#F3778F',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#F3778F',
      },
    },
  });

  const theme = useTheme();

  return (
   
    <React.Fragment>
      <CssBaseline />
      <Container
        maxWidth="md"
        style={{
          display: "flex",
          justifyContent: "center",
          /*alignItems: "center",*/
          height: "100vh",
        }}>
        <Box
          mt={10}
          sx={{
            borderRadius: "30px", // Border radius
            marginBottom: "80px",
            backgroundImage: `url(${backgroundCalcul})`, // Use template literals
            backgroundSize: "cover", // Adjust as needed
            p: "2vw",
            width: "50vw",
            height: "auto",
            [theme.breakpoints.down("sm")]: {
              height: "80vh", // Adjusted height for small screens
              width: "80vw",
              p: "4vw",
            },
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center", // Center vertically
            alignItems: "center", // Center horizontally
          }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            marginBottom={3}>
            <Typography
              variant="h2"
              className="typo-responsive"
              sx={{
                color: "#F3778F",
                textAlign: "center",
                fontFamily: "Majetto",
                fontSize: {
                  xs: '6vw', // Font size for extra small screens
                  sm: '3.5vw', // Font size for small screens
                  md: '3.5vw', // Font size for medium screens
                  lg: '3.5vw', // Font size for large screens
                  xl: '3.5vw' // Font size for extra large screens
              },
                fontStyle: "normal",
                fontWeight: 400,
                textTransform: "uppercase",
              }}>
              Calcul numérologique
            </Typography>
            <Typography
              variant="h4"
              style={{
                color: "var(--taupe-fonc, #66534D)",
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 400,
              }}>
              Chaque lettre sera convertie en chiffre pour calculer votre chiffre d'expression.
            </Typography>
          </Grid>

          <Grid
            container
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
            marginBottom={8}>
            <Grid item xs={12} md={6}>
              <CssTextField
                autoFocus={shouldFocus} // Control autofocus dynamically                
                label="Enter votre prénom"
                variant="outlined"
                color="secondary"
                onChange={handleInputChange}
                value={inputValue}
                style={{
                  width: "100%",
                  maxWidth: "80vw",
                  height: "6vh",
                  flexShrink: 0,
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "21px",
                  backgroundColor: "#FFFFFF", // Fond blanc
                  borderColor: borderColor, // Couleur de la bordure définie par l'état
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                component={Button}
                variant="contained"
                color="primary"
                onClick={() => handleCalculate(inputValue)}
                style={{
                  width: "100%",
                  maxWidth: "80vw",
                  height: "6vh",
                  flexShrink: 0,
                  borderRadius: "5px",
                  background: "var(--taupe-clair, #F3778F)",
                  boxShadow: "4px 24px 60px 0px rgba(109, 141, 173, 0.25)",
                  color: "var(--white, #FEFEFE)",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "28px",
                }}>
                Calculer
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            marginBottom={3}>
          </Grid>
          {showResults && (
            <React.Fragment>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                marginBottom={5}>
                <EllipseWithTextIcon calculatedResult={result} />
              </Grid>

              <Grid
                container
                justifyContent="center"
                alignItems="center"
                marginBottom={8}>
                <Typography
                  variant="body1"
                  style={{
                    color: "var(--taupe-clair, #8D7268)",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "21px",
                    marginTop: "1rem", // Add margin at the top
                  }}>
                  Issu de {resultPath}
                </Typography>
              </Grid>

              {/* Here is the additional phrase */}
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                marginBottom={3}>
                <Typography
              variant="h3"
              style={{
                color: "var(--taupe-fonc, #F3778F)",
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 400,
                textDecoration: "underline",
                cursor: "pointer", // Change cursor to pointer
              }}
              onClick={handleClick} // Add click handler
            >
              Découvrir la signification de votre calcul numérologique
            </Typography>
              </Grid>
            </React.Fragment>
          )}

            {/* Modal for offline message */}
          <Modal show={showLoginMessage} onHide={handleCloseLogin}>
              <Modal.Header closeButton>
                <Modal.Title>Message de connexion</Modal.Title>
              </Modal.Header>
            <Modal.Body>
              <p>Veuillez vous connecter pour voir la signification.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseLogin}>Fermer</Button>
              <Link to="/login">
                <Button variant="primary">Connexion</Button>
              </Link>
            </Modal.Footer>
          </Modal> 

          <Modal show={showAbonnementMessage} onHide={handleCloseAbonnement}>
            <Modal.Header closeButton>
              <Modal.Title>Abonnement requis</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Vous devez avoir un abonnement pour voir la signification.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseAbonnement}>Fermer</Button>
              <Link to="/subscriptions">
                <Button variant="primary">Acheter un abonnement</Button>
              </Link>
            </Modal.Footer>
          </Modal>

          <Dialog
  open={showMeaningMessage}
  onClose={handleCloseMeaning}
  aria-labelledby="meaning-dialog-title"
  aria-describedby="meaning-dialog-description"
  maxWidth="md" // Adjust the size as needed
  fullWidth
>
  <DialogTitle id="meaning-dialog-title" marginBottom={5} style={{color:"var(--taupe-clair, #F3778F)"}}>
    Signification de votre calcul numérologique
  </DialogTitle>
  <DialogContent>
    {/* Display results conditionally */}
    {showResults && (
      <React.Fragment>
        <Grid container spacing={2}>
          {/* First Column: Result Information */}
          <Grid item xs={12} sm={6} container justifyContent="center" alignItems="center">
            <Grid container justifyContent="center" alignItems="center" marginBottom={2}>
              <EllipseWithTextIcon calculatedResult={result} />
            </Grid>
            <Typography
              variant="body1"
              style={{
                color: "var(--taupe-clair, #8D7268)",
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "21px",
                marginTop: "1rem",
              }}
            >
              Issu de {resultPath}
            </Typography>
          </Grid>

          {/* Second Column: Checkboxes */}
          <Grid item xs={12} sm={6} container justifyContent="left" alignItems="center">
            <Grid container justifyContent="left">
              <Typography variant="body2" style={{ fontSize: "18px", color: "#8D7268" }}>
                Choisir une option :
              </Typography>
            </Grid>
            <Grid container justifyContent="left" style={{ marginTop: '1rem' }}>
              <FormControl component="fieldset">
                <FormGroup>
                  {/* Render checkboxes and input */}
                  {['Prénom / Nom', 'Entreprise', 'Adresse'].map((option) => (
                    <React.Fragment key={option}>
                      {/* Render the selected checkbox, hide others */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={option}
                            checked={selectedOption === option}
                            onChange={handleCheckboxChange}
                            sx={{
                              color: "#8D7268",
                              '&.Mui-checked': {
                                color: "#8D7268",
                              },
                            }}
                          />
                        }
                        label={option.charAt(0).toUpperCase() + option.slice(1)}
                      />
                    </React.Fragment>
                  ))}
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        {/* Result dialog for meaning */}
        <Dialog open={showResultsFinal} onClose={() => setShowResultsFinal(false)}>
          <DialogTitle style={{ color: "var(--taupe-clair, #F3778F)" }}>Résultat de la numérologie</DialogTitle>
          <DialogContent>
            <Grid container justifyContent="center" alignItems="center" marginBottom={5}>
              <EllipseWithTextIcon calculatedResult={result} />
            </Grid>
            <Typography
              variant="body1"
              style={{
                color: "var(--taupe-clair, #8D7268)",
                fontFamily: "Roboto",
                fontSize: "18px",
                textAlign: "center",
              }}
            >
              Issu de {resultPath}
            </Typography>
            <br />
            {customText && <p>{customText}</p>} {/* Display custom text */}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowResultsFinal(false)} style={{ background: "var(--taupe-clair, #F3778F)", color: "var(--white, #FEFEFE)" }}>
              Fermer
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )}
  </DialogContent>

  <DialogActions>
    <Button onClick={handleCloseMeaning} style={{ background: "var(--taupe-clair, #F3778F)", color: "var(--white, #FEFEFE)" }}>
      Fermer
    </Button>
  </DialogActions>
</Dialog>


        </Box>
      </Container>
    </React.Fragment>
    
  );
};

// SvgIcon for Ellipse
const EllipseWithTextIcon = ({ calculatedResult }) => (
  <svg
    viewBox="0 0 100 100"
    width="90"
    height="90"
    fill="var(--taupe-clair, #8D7268)">
    <ellipse cx="50" cy="50" rx="50" ry="50" />
    <text
      x="50"
      y="50"
      textAnchor="middle"
      dy="0.3em"
      fill="var(--white, #FEFEFE)"
      fontFamily="Roboto"
      fontSize="65px"
      fontStyle="normal"
      fontWeight="400"
      lineHeight="65px"
      transform="uppercase">
      {calculatedResult}
    </text>
  </svg>
);

export default NumerologyCalculator;

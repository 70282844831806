import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Row, Col, Button, Input, /* Spinner */ FormGroup, FormFeedback} from 'reactstrap';
import Alert from '@mui/material/Alert';
import classes from './SignUpForm.module.css';
import axios from '../../Utils/axios';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { IoPerson, IoLocation, IoLockClosed} from 'react-icons/io5';
import { BsAt, BsHouses, BsShieldShaded} from "react-icons/bs";
import { FaUsers, FaPhone} from "react-icons/fa";
import { Stack, Stepper, Step, StepLabel } from '@mui/material';
import ColorlibConnector from './Components/ColorlibConnector'; 
import ColorlibStepIcon from './Components/ColorlibStepIcon'; 
import Swal from 'sweetalert2';

const steps = ['Remplir votre données', 'Confirmer e-mail'];

function SignUpForm() {
  const [userData, setUserData] = useState({
    nom_client: '',
    prenom_client: '',
    addresse_client: '',
    ville_client: '',
    phone_client: '',
    password_client: '',
    cp_client: '66000',
    province_client: 'perpignan',
    email_client: ''
  });

  const initialValidationStatus = {
    email_client: true,
    prenom_client: true,
    nom_client: true,
    phone_client: true,
    ville_client: true,
    addresse_client: true,
    password_client: true,
  };

  const [IsNotValidForm, setIsNotValidForm] = useState(false);
  const [validationStatus, setValidationStatus] = useState(initialValidationStatus);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [cpswd, setCpswd] = useState('');
  const navigate = useNavigate();

  const handleNextStepChange = async () => {
    const fields = Object.keys(userData);
    const updatedValidationStatus = { ...validationStatus };
    let isValidForm = true;

    await Promise.all(fields.map(async (field) => {
      updatedValidationStatus[field] = userData[field] !== '';
      if (!userData[field]) {
        isValidForm = false;
      }
    }));

    setValidationStatus(updatedValidationStatus);

    const isValidEmail = await handleBlurValidateEmail(userData.email_client);
    const isPasswordMatch = await handleBlurCPassword(cpswd);

    isValidForm = isValidForm && isValidEmail && isPasswordMatch;
    setIsNotValidForm(!isValidForm);
    if (isValidForm) {
      setActiveStep(1);
    }
  };

  const handlePrevStepChange = () => {
    setActiveStep(0);
  };

  const handleBlurValidateEmail = async (email) => {
    const isValid = /\S+@\S+\.\S+/.test(email);
    setValidationStatus((prevValidationStatus) => ({
      ...prevValidationStatus,
      email_client: isValid,
    }));
    return isValid;
  };

  const handleBlurCPassword = async (cpswd) => {
    const match = cpswd === userData.password_client && cpswd !== '';
    setValidationStatus((prevValidationStatus) => ({
      ...prevValidationStatus,
      password_client: match,
    }));
    return match;
  };

  const handleInputChangeOnBlur = (input, type) => {
    setValidationStatus((prevValidationStatus) => ({
      ...prevValidationStatus,
      [type]: input !== '',
    }));
  };

  const handleSignUp = async (e) => {
    e.preventDefault();  // Prevent default form submission
    console.log("handleSignUp function called");

    if (!IsNotValidForm) {
      setLoading(true);

      try {
        console.log("Sending user data:", userData);
        console.log("Sending user data as JSON:", JSON.stringify(userData));

        const response = await axios.post('/clients/inscription', userData, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        setLoading(false);
        console.log("Server response:", response);
        Swal.fire({
          title: 'Inscription réussite',
          text: 'Vérifiez votre boîte mail pour confirmer votre adresse e-mail',
          icon: 'success',
          confirmButtonColor: 'LightPink',
        }).then(() => {
          navigate('/', { state: response.data.message });
        });
      } catch (error) {
        setLoading(false);
        console.error("Error message:", error.message);
        console.error("Error response:", error.response?.data);
        Swal.fire({
          title: 'Inscription non réussite',
          text: 'Cette adresse existe déjà !',
          icon: 'error',
          confirmButtonColor: 'LightPink',
        });
      }
    } else {
      toast.error('Passwords do not match');
    }
  };
  
  return (
    <form>
      <Row className={classes.formContainer}>
          <ToastContainer />
        <Col xs={12} md={8} className="mx-auto">
          <div className={classes.horizontalBarContainer}>
            <div className={classes.horizontalBar}></div>
            <h4 className={classes.headerTitle}>INSCRIPTION</h4>
            <div className={classes.horizontalBar}></div>
          </div>
          <Row>
            <Col xs={12} md={10} className='mx-auto'>
              <Stack sx={{ width: '100%' }} className='mb-5'>
                <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                  {steps.map(label => (
                    <Step key={label}>
                      <StepLabel
                        StepIconComponent={ColorlibStepIcon}
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Stack>
            </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className='mx-auto mb-3'>
                {IsNotValidForm &&(
                  <Alert severity="info" className='d-flex justify-content-center'>Verifier Vos Informations</Alert>
                )}
              </Col>
            </Row>
            <Row>
            <Col xs={12} md={6} className='mx-auto'>
              <div className={`mb-3 ${classes.inputWithIcon}`} >
                <FormGroup>
                  <Input
                    id="exampleEmail"
                    name="email"
                    type="email"
                    onChange={(e) => {
                      setUserData({ ...userData, email_client: e.target.value });
                    }}
                    onBlur={(e) => handleBlurValidateEmail(e.target.value)}
                    className={`${classes.input}`}
                    placeholder='Adresse e-mail'
                    invalid={!validationStatus.email_client}
                  />
                  <FormFeedback>
                    Please enter a valid email.
                  </FormFeedback>
                </FormGroup>
                {validationStatus.email && (<span className={classes.icon}>
                  <BsAt />
                </span>)}
              </div>
            </Col>
          </Row>
          <Row style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            <Col xs={12} md={3}>
              <div className={`mb-3 ${classes.inputWithIcon}`} >
                <FormGroup>
                  <Input
                    className={classes.input}
                    type="text"
                    placeholder="Prénom"
                    onBlur={(e) => handleInputChangeOnBlur(e.target.value, 'prenom_client')}
                    onChange={(e) => {
                      setUserData({ ...userData, prenom_client: e.target.value });
                    }}
                    invalid={!validationStatus.prenom_client}
                  />
                  <FormFeedback>
                    The fuild is required.
                  </FormFeedback>
                </FormGroup>
                {validationStatus.prenom && (<span className={classes.icon}>
                  <IoPerson />
                </span>)}
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className={`mb-3 ${classes.inputWithIcon}`} >
                <FormGroup>
                  <Input
                    className={classes.input}
                    type="text"
                    placeholder="Nom"
                    onBlur={(e) => handleInputChangeOnBlur(e.target.value, 'nom_client')}
                    onChange={(e) => {
                      setUserData({ ...userData, nom_client: e.target.value });
                    }}
                    invalid={!validationStatus.nom_client}
                  />
                  <FormFeedback>
                    The fuild is required.
                  </FormFeedback>
                </FormGroup>
                {validationStatus.nom_client && (<span className={classes.icon}>
                  <FaUsers />
                </span>)}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className='mx-auto'>
              <div className={`mb-3 ${classes.inputWithIcon}`} >
                <FormGroup>
                  <Input
                    id="phone"
                    name="phone"
                    type="text"
                    onBlur={(e) => handleInputChangeOnBlur(e.target.value, 'phone_client')}
                    onChange={(e) => {
                      setUserData({ ...userData, phone_client: e.target.value });
                    }}
                    invalid={!validationStatus.phone_client}
                    className={classes.input}
                    placeholder='Numéro de téléphone'
                  />
                  <FormFeedback>
                    The fuild is required.
                  </FormFeedback>
                </FormGroup>
                {validationStatus.phone_client && (<span className={classes.icon}>
                  <FaPhone />
                </span>)}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className='mx-auto'>
              <div className={`mb-3 ${classes.inputWithIcon}`} >
                <FormGroup>
                  <Input
                    id="ville"
                    name="ville"
                    type="text"
                    onBlur={(e) => handleInputChangeOnBlur(e.target.value, 'ville_client')}
                    onChange={(e) => {
                      setUserData({ ...userData, ville_client: e.target.value });
                    }}
                    invalid={!validationStatus.ville_client}
                    className={classes.input}
                    placeholder='Ville'
                  />
                  <FormFeedback>
                    The fuild is required.
                  </FormFeedback>
                </FormGroup>
                {validationStatus.phone_client && (<span className={classes.icon}>
                  <BsHouses />
                </span>)}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className='mx-auto'>
              <div className={`mb-3 ${classes.inputWithIcon}`} >
                <FormGroup>
                  <Input
                    id="adr"
                    name="adr"
                    type="text"
                    onBlur={(e) => handleInputChangeOnBlur(e.target.value, 'addresse_client')}
                    onChange={(e) => {
                      setUserData({ ...userData, addresse_client: e.target.value });
                    }}
                    invalid={!validationStatus.addresse_client}
                    className={classes.input}
                    placeholder='Addresse'
                  />
                  <FormFeedback>
                    The fuild is required.
                  </FormFeedback>
                </FormGroup>
                {validationStatus.addresse_client && (<span className={classes.icon}>
                  <IoLocation />
                </span>)}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className='mx-auto'>
              <div className={`mb-3 ${classes.inputWithIcon}`} >
                <FormGroup>
                  <Input
                    id="pswd"
                    name="pswd"
                    type="password"
                    value={userData.password}
                    onChange={(e) => {
                      setUserData({ ...userData, password_client: e.target.value });
                    }}
                    invalid={!validationStatus.password_client}
                    className={classes.input}
                    placeholder='Mot de passe'
                  />
                  <FormFeedback>
                    Passwords didn't match.
                  </FormFeedback>
                </FormGroup>
                {validationStatus.password_client && (<span className={classes.icon}>
                  <IoLockClosed />
                </span>)}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className='mx-auto'>
              <div className={`mb-3 ${classes.inputWithIcon}`} >
                <Input
                    id="confirmPswd"
                    name="confirmPswd"
                    type="password"
                    onChange={(e) => {
                      setCpswd(e.target.value );
                    }}
                    onBlur={(e) => handleBlurCPassword(e.target.value)}
                    className={classes.input}
                    invalid={!validationStatus.password_client && cpswd !== ''}
                    placeholder='Confirmer le mot de passe'
                  />
                  {
                    validationStatus.password_client &&
                      <span className={classes.icon}>
                        <BsShieldShaded />
                      </span>
                  }
                </div>
            </Col>
          </Row>
          {activeStep === 0 ? (
            <Row className='mt-4'>
              <Col xs={12} md={3} className='mx-auto d-flex justify-content-center'>
                  <Button className={`btn ${classes.buttonStyle} btn-sm`} onClick={() => handleNextStepChange()} >
                    Suivant
                  </Button>
              </Col>
            </Row>
            ) : activeStep === 1 && !IsNotValidForm && (
              <Row  className= 'mt-4' style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                <Col xs={12} md={3} className='d-flex justify-content-center mb-3'>
                  <Button className={`btn ${classes.buttonBackStyle} btn-sm`} onClick={() => handlePrevStepChange()}>
                     Retour
                  </Button>
                </Col>
                <Col xs={12} md={3} className='d-flex justify-content-center mb-3'>
                  <Button className={`btn ${classes.buttonStyle} btn-sm`} onClick={handleSignUp} type="submit">
                    S'inscrire
                  </Button>
                </Col>
              </Row>
            )}
          <Row className='mt-4 mb-4'>
            <Col xs={12} md={6} className="mx-auto text-center">
              <h4 style={{ color: 'rgb(130,130,130)', fontSize: '15px'}}>
                Vous avez déjà un compte ?{' '}
                <NavLink to="/" style={{ textDecoration: 'none' }}>
                  Se Connecter
                </NavLink>
              </h4>
            </Col>
          </Row>
        </Col>
      </Row>
    </form>
      
  );
}

export default SignUpForm;

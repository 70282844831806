import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBTypography,
} from "mdb-react-ui-kit";
import { Button, Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { BiCheckCircle } from "react-icons/bi";
import { ClipLoader } from "react-spinners"; // Importer ClipLoader depuis react-spinners

export default function PackvideoDetails() {
  const { id } = useParams();
  const [packvideo, setPackvideo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showLoginMessage, setShowLoginMessage] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_APIURL}/public/packvideo/${id}`)
      .then((response) => {
        setPackvideo(response.data.result);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching pack video details:", error);
        setLoading(false);
      });
  }, [id]);

  const handleClickCommander = () => {
    setLoading(true);

    const token = localStorage.getItem("access_token_story");
    if (!token) {
      setShowLoginMessage(true);
      setLoading(false);
      return;
    }

    const data = {
      itemToBuyId: id,
      itemToBuyType: "PACK_VIDEO",
      token: token,
    };

    axios
      .post(
        `${process.env.REACT_APP_BILLING_URL}/create-checkout-session`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setLoading(false);
        window.location.href = response.data.url;
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error during checkout:", error);
      });
  };

  if (loading) {
    return (
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <div className="text-center">
          <ClipLoader color="#F3778F" loading={loading} size={50} />
          <p className="mt-3">Veuillez patienter quelques instants...</p>
        </div>
      </div>
    );
  }

  if (!packvideo) {
    return <div>Pack vidéo non trouvé.</div>;
  }

  const maxLength = 300;
  const truncatedDescription =
    packvideo.description.length > maxLength
      ? packvideo.description.substring(0, maxLength) + "..."
      : packvideo.description;

  const descriptionArray = truncatedDescription.split(".");

  return (
    <section className="vh-80 bg-header-abonnement" style={{ backgroundColor: "#f4f5f7" }}>
      <MDBContainer className="py-5 h-80">
        <MDBRow className="justify-content-center align-items-center h-80">
          <MDBCol lg="8" className="mb-4 mb-lg-0">
            <MDBCard className="mb-3" style={{ borderRadius: ".5rem", backgroundColor: "#ffffff !important" }}>
              <MDBRow className="g-0">
                <MDBCol md="4" className="gradient-custom text-center text-white" style={{ borderTopLeftRadius: ".5rem", borderBottomLeftRadius: ".5rem" }}>
                  <MDBCardImage src={packvideo.image} alt={packvideo.name} className="my-5" style={{ width: "80px" }} fluid />
                  <MDBTypography tag="h5" style={{ color: "#8d7268" }}>{packvideo.name}</MDBTypography>
                </MDBCol>
                <MDBCol md="8">
                  <MDBCardBody className="p-4">
                    <MDBTypography tag="h3" style={{ color: "#8d7268" }}>Information  des jetons</MDBTypography>
                    <hr className="mt-0 mb-4" />
                    <MDBRow className="pt-1">
                      <MDBCol size="" className="mb-3">
                        <MDBTypography tag="h5">Description</MDBTypography>
                        <MDBCardText className="text-muted">
                          {descriptionArray.map((line, index) => (
                            <React.Fragment key={index}>
                              {line.trim() && <BiCheckCircle className="me-1" />}
                              {line.trim()} <br />
                              
                            </React.Fragment>
                          ))}
                        </MDBCardText>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow className="pt-1">
                      <MDBCol size="6" className="mb-3">
                        <MDBTypography tag="h5">Prix</MDBTypography>
                        <MDBCardText className="text-muted">{packvideo.price} €</MDBCardText>
                      </MDBCol>
                      <MDBCol size="6" className="mb-3">
                        <MDBTypography tag="h5">Nombre de vidéos jetons </MDBTypography>
                        <MDBCardText className="text-muted">{packvideo.token_amount}</MDBCardText>
                      </MDBCol>
                    </MDBRow>
                    <br />
                    <div className="d-flex justify-content-end">
                      <Button className="btn btn-sm abonner" onClick={handleClickCommander}>Acheter</Button>
                    </div>
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <Modal show={showLoginMessage} onHide={() => setShowLoginMessage(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Message de connexion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Veuillez vous connecter pour passer une commande.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLoginMessage(false)}>Fermer</Button>
          <Link to="/login">
            <Button variant="primary">Connexion</Button>
          </Link>
        </Modal.Footer>
      </Modal>
    </section>
  );
}

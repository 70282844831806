import React from 'react';
import TitleSection from "./SubscriptionTitle";
import SubscriptionsHome from '../../../SubscriptionsHome/SubscriptionsHome';
import "./CombinedSubscriptionSection.css";
import backgroundImage from '../../../../../../../Assets/images/accueil-abonnements-story.jpg';


const CombinedSubscriptionSection = () => {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover', // Adjust this property based on your needs
    // Add any other necessary styles
  };

  return (
    <div className="abonnement_background" style={backgroundStyle}>
      <TitleSection />
      <SubscriptionsHome />
    </div>
  );
};

export default CombinedSubscriptionSection;